import dayjs from "dayjs";

/**
 * @param {'h'|'d'|'m'|'f'|'s'} grouping
 */
export function dateFormatter (grouping = "f") {
	if (grouping === "h")
		return value => dayjs(value).format("HH:mm");

	if (grouping === "d")
		return value => dayjs(value).format("DD/MM/YYYY");

	if (grouping === "m")
		return value => dayjs(value).format("MM/YYYY");

	if (grouping === "s")
		return value => dayjs(value).format("DD/MM/YYYY HH:mm:ss");

	return value => dayjs(value).format("DD/MM/YYYY HH:mm");
}

window.dayjs = dayjs;

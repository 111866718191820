import Axios from "axios";
import dayjs from "dayjs";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class TrendsService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	/**
	 * @param {number[]} idVariables
	 * @param {Date} start
	 * @param {Date} end
	 * @param {number} interval
	 */
	async getData (idVariables, start, end, interval, limites) {
		const response = await this.axios.get("trends", {
			params: {
				id_variables: idVariables.join(","),
				start: dayjs(start).format(),
				end: dayjs(end).format(),
				interval,
				limites
			}
		});

		return response.data;
	}

	/**
	 * @param {number[]} idVariables
	 * @param {Date} start
	 * @param {number} interval
	 */
	async getOnlineData (idVariables, start, interval, limites) {
		const response = await this.axios.get("trends/online", {
			params: {
				id_variables: idVariables.join(","),
				start: dayjs(start).format(),
				interval,
				limites
			}
		});

		return response.data;
	}
}

import { dateFormatter } from "./helpers";
import "echarts/lib/chart/line";

import baseChartFactory from "./base.echarts";

const tooltipDateFormatter = dateFormatter("s");

const formatterAxisPointer = v => {
	if (v.axisDimension === "x")
		return tooltipDateFormatter(v.value);

	return v.value.toFixed(2);
};

/**
 * @param {string[]} legend
 * @param {object[]} series Cada série representa uma linha no gráfico.
 * @param {string} series[].name nome na legenda
 * @param {'line'} series[].type
 * @param {string} series[].symbol
 * @param {number} series[].symbolSize
 * @param {object[]} series[].data valores da série temporal
 * @param {number} series[].data[].name timestamp
 * @param {[number, number]} series[].data[].value array de [timestamp, valor]
 * @param {'h'|'d'|'m'} grouping
 * @param {function} [toggleMarkLine]
 */
export default function optionsFactory (title, legend, series, grouping = "f", toggleMarkLine, digitalCount = null) {
	return {
		...baseChartFactory({
			title,
			legend,
			series,
			yAxisZoom: true,
			toggleMarkLine
		}),
		axisPointer: {
			type: "cross",
			label: {
				formatter: formatterAxisPointer
			}
		},
		xAxis : [{
			type: "time",
			show: digitalCount > 0 || digitalCount === null,
			axisLabel: {
				formatter: dateFormatter(grouping)
			}
		},
		...(digitalCount !== null ? [{
			type: "time",
			show: true,
			axisLabel: {
				formatter: dateFormatter(grouping)
			},
			gridIndex: 1
		}] : [])]
	};
}

import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/markLine";

import dayjs from "dayjs";

function dataZoomFactory (x = true, y = false) {
	const dz = [];
	if (x) {
		dz.push(
			{ type: "slider", top: "6.75%", orient: "horizontal" },
			{ type: "inside", orient: "horizontal" }
		);
	}

	if (y) {
		dz.push(
			{ type: "slider", orient: "vertical" },
			{ type: "inside", orient: "vertical", yAxisIndex: 1}
		);
	}

	return dz.length ? { dataZoom: dz } : {};
}

function makeFeatures (xAxisZoom, yAxisZoom, toggleMarkLine, type) {
	const features = {
		restore: {
			title: "Restaurar"
		},
		dataView: {
			title: "Ver Dados",
			lang: ["Dados do Gráfico", "Sair", "Atualizar"]
		},
		saveAsImage: {
			title: "Salvar imagem"
		}
	};

	if (type !== "pie" && type !== "bar" && toggleMarkLine) {
		features.myMarkLine = {
			show: true,
			title: "Mostrar/esconder limites",
			icon: "path://M554 384v-86h-84v86h84zM554 726v-256h-84v256h84zM512 86c236 0 426 190 426 426s-190 426-426 426-426-190-426-426 190-426 426-426z",
			onclick: () => {
				toggleMarkLine();
			}
		};
	}

	if (xAxisZoom || yAxisZoom) {
		features.dataZoom = {
			title: {
				zoom: "Ampliar área",
				back: "Restaurar ampliação"
			},
			yAxisIndex: yAxisZoom && undefined,
			xAxisIndex: xAxisZoom && undefined
		};
	}

	return features;
}

function yAxis (noAxis = false) {
	return noAxis ? {} : {
		yAxis: {
			type: "value",
			boundaryGap: [0, "100%"],
			axisLine: { onZero: false },
			splitLine: { show: true },
			minorSplitLine: { show: true }
		}
	};
}

/**
 * @param {object} [param0]
 * @param {string} [param0.title]
 * @param {string[]} [param0.legend]
 * @param {any[]} [param0.series]
 * @param {boolean} [param0.yAxisZoom]
 * @param {boolean} [param0.noAxis]
 * @param {function} [param0.toggleMarkLine]
 */
export default function optionFactory ({
	title = "Sem título",
	legend = [],
	series = [],
	xAxisZoom = true,
	yAxisZoom = false,
	noAxis = false,
	toggleMarkLine
} = {}) {
	const type = series.length > 0 ? series[0].type : undefined;
	const trigger = type === "bar" || type === "pie" ? "axis" : "item";

	const optionsObject = {
		title: {
			text: title
		},
		legend: {
			type: "scroll",
			data: legend,
			bottom: 0
		},
		toolbox: {
			show: true,
			feature: makeFeatures(xAxisZoom, yAxisZoom, toggleMarkLine, type)
		},
		tooltip: {
			trigger,
			axisPointer: {
				type: "cross",
				animation: false
			}
		},
		...dataZoomFactory(xAxisZoom, yAxisZoom),
		...yAxis(noAxis),
		animationDuration: 1000,
		series
	};

	if (trigger === "item") {
		optionsObject.formatter = function (params) {
			if (params.componentSubType === "line" && trigger === "item") {
				const colorSpan = color => '<span style="display:inline-block;margin-right:3px;border-radius:50%;width:9px;height:9px;background-color:' + color + '"></span>';
				let tooltipText = dayjs(params.value[0]).format("DD/MM/YYYY HH:mm:ss") + "<br>";
				const itemText = colorSpan(params.color) + " " + params.seriesName + ": " + params.value[1] + "<br>";
				tooltipText += itemText;

				return tooltipText;
			}
			if (typeof params === "string") return params;
		};
	}

	return optionsObject;
}
